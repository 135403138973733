<template>
  <v-container class="text-center" height="500" justify="center">
    <h1>Textblöcke</h1>

    <textblock-form textSlug="about" />
    <textblock-form textSlug="info" />
    <textblock-form textSlug="gallery" />

    <!-- <admin-single-image :imgSlug="'frontPage'" /> -->
  </v-container>
</template>

<script>
import TextblockForm from "@/adminComponents/AdminTextField.vue";
// import AdminSingleImage from '../../adminComponents/AdminSingleImage.vue'

export default {
  components: {
    TextblockForm
    // AdminSingleImage
  }
};
</script>
