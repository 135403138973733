<template>
  <div>
    <error-message v-for="(e, index) in errors" :key="index" :error="e" />
    <v-card v-if="profile">
      <v-card-title>{{ profile.email }}</v-card-title>
      <v-card-text>
        Password ändern:
        <v-form ref="form">
          <v-text-field
            v-model="newPassword"
            label="neues Passwort"
            :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPW = !showPW"
            :rules="[rules.required, rules.min]"
            :type="showPW ? 'text' : 'password'"
            counter
          />
          <v-text-field
            v-model="newPW2"
            label="bitte Passwort wiederholen"
            :rules="[rules.required, rules.min, rules.match]"
            type="password"
            counter
          />
          <v-btn @click="savePassword">
            neues Password speichern
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="snackbar" timeout="5000">
      {{ SnackBarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import errorMessage from "../../components/errorMessage.vue";
import utils from "@/utils";

export default {
  components: { errorMessage },
  data() {
    return {
      errors: [],
      profile: false,
      newPassword: "",
      newPW2: "",
      showPW: false,
      snackbar: false,
      SnackBarText: "",
      rules: {
        required: value => !!value || "Bitte neues Passwort angeben.",
        min: v =>
          v.length >= 8 || "Passwort muss mindestens 8 Zeichen lang sein",
        match: value =>
          value == this.newPassword || "Beide Passwörter müssen identisch sein"
      }
    };
  },
  methods: {
    async savePassword() {
      this.errors = [];
      // error checking
      if (!this.newPassword) {
        this.errors.push("Bitte zuerst ein neues Passwort angeben");
      }
      if (this.newPassword.length < 8) {
        this.errors.push("Passwort zu kurz; bitte mindestens 8 Zeichen");
      }
      if (this.newPW2 != this.newPassword) {
        this.errors.push("Passwörter stimme nicht überein");
      }

      if (!this.errors.length) {
        try {
          let pwData = {
            email: this.profile.email,
            password: this.newPassword
          };
          if (await utils.saveNewPassword(pwData)) {
            // reset:
            this.$refs.form.reset();
            this.SnackBarText = "Passwort geändert";
            this.snackbar = true;
            return;
          } else {
            this.errors.push(
              "Konnte neues Passwort nicht speicher; bitte erneut versuchen."
            );
          }
        } catch (e) {
          this.errors.push(e);
        }
      } else {
        return;
      }
    },
    async loadProfile() {
      try {
        this.profile = await utils.loadProfile();
      } catch (e) {
        this.errors.push(e);
      }
    }
  },
  mounted() {
    this.loadProfile();
  }
};
</script>
